import * as React from 'react'
import Layout from "../components/layout/layout";
import {Link} from 'gatsby'

const AboutPage = () => {
    return (
        <Layout pageTitle="404 Not Found" pageKeywords="not found" pageDescription="Not found page">
            <div className="row">
                <div className="col-md-2"/>
                <div className="col-md-8">
                    <div className="page">
                        <div className="content">
                            <div className="text-center">
                                <h3>Ooops, 404 not found :(</h3>
                                <hr />
                                <h3>BeltHosting couldn't find this page, maybe try <Link to="/">home</Link>?</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-2"/>
            </div>

        </Layout>
    )
}
export default AboutPage
